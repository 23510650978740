import { Component, OnInit } from '@angular/core';
import { AdministradorapiService } from '../../../services/administradorapi.service';
import { ActivatedRoute } from '@angular/router';
import { Administrador } from '../../../models/Administrador';

@Component({
  selector: 'app-lista-admin',
  templateUrl: './listaadmin.component.html',
  styleUrls: ['./listaadmin.component.scss']
})
export class ListaAdminComponent implements OnInit {

  administradores: Administrador[] = [];
  loading: boolean;
  constructor(private api: AdministradorapiService, private actRoute: ActivatedRoute) {
    // constructor(){
    actRoute.params.subscribe(data => {
      if (data) {
        // console.log(data.id);
        this.recargarAdministradores();
      }

    });


  }
  recargarAdministradores() {
    this.loading = true;
    this.api.getListaadministradores().subscribe(listaAdministradores => {
      this.administradores = listaAdministradores;
      this.loading = false;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  ngOnInit() {
  }
  eliminarAdministrador(id) {
    console.log(id);
    this.loading = true;
    this.api.deleteAdministrador(id).subscribe(res => {
      this.loading = false;
      if (res !== 'success') {
        console.log(res);
      } else {
        this.recargarAdministradores();
      }
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }


}
