import { Component, OnInit, Input } from '@angular/core';
import { DatoCarrera } from '../../../models/DatosHojaDeVida';

@Component({
  selector: 'app-unacarrera',
  templateUrl: './unacarrera.component.html',
  styleUrls: ['./unacarrera.component.scss']
})
export class UnacarreraComponent implements OnInit {
  possibleColors = ['success', 'info', 'warning', 'danger', 'primary', 'secondary', 'dark'];
  currRandom: number;
  @Input() carrera: DatoCarrera;
  @Input() total: number;
  constructor() {
    this.currRandom = Math.random();
  }

  ngOnInit() {
  }
  getProgressBarType() {
    return this.possibleColors[Math.floor(this.currRandom * this.possibleColors.length)];
  }
  getProgressBarPercentage() {
    return Math.floor(this.carrera.total / this.total);
  }


}
