import { Injectable } from '@angular/core';
import { ImagenBottom, ImagenBottomSingleResponse, ImagenBottomResponse } from '../models/Imagenbottom';
import { map } from 'rxjs/operators';
import { BaseService } from './baseapi.service';

@Injectable({
  providedIn: 'root'
})
export class ImagenesbottomapiService extends BaseService {


  createImagenBottom(imagenbottom: ImagenBottom) {
    return this.postBaseRequest<ImagenBottomSingleResponse>('api/imagenesbottom', imagenbottom)
      .pipe(map(data => data.data));
  }
  updateImagenBottom(imagenbottom: ImagenBottom) {
    return this.putBaseRequest<ImagenBottomSingleResponse>('api/imagenesbottom/' + imagenbottom.id, imagenbottom)
      .pipe(map(data => data.data));
  }
  deleteImagenBottom(id) {
    return this.deleteBaseRequest<ImagenBottomSingleResponse>('api/imagenesbottom/' + id)
      .pipe(map(data => data.res));
  }

  getListaImagenBottoms() {
    return this.getBaseRequest<ImagenBottomResponse>('api/imagenesbottom')
      .pipe(map(data => data.data));
  }
  getImagenBottom(id: string) {
    return this.getBaseRequest<ImagenBottomSingleResponse>('api/imagenesbottom/' + id)
      .pipe(map(data => data.data));
  }
  enviarFoto(datos: any, id) {
    return this.postFileRequest<ImagenBottomSingleResponse>('api/imagenesbottom/' + id + '/foto', datos)
      .pipe(map(data => data.data));

  }
}
