import { Component, OnInit } from '@angular/core';
import { ImagenesbottomapiService } from '../../../services/imagenesbottomapi.service';

import { ActivatedRoute } from '@angular/router';
import { ImagenBottom } from 'src/app/models/Imagenbottom';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-listaimagenbottom',
  templateUrl: './listaimagenbottom.component.html',
  styleUrls: ['./listaimagenbottom.component.scss']
})
export class ListaimagenbottomComponent implements OnInit {

  imagenesbottom: ImagenBottom[] = [];
  loading: boolean;
  constructor(private api: ImagenesbottomapiService, private actRoute: ActivatedRoute) {
    // constructor(){
    actRoute.params.subscribe(data => {
      if (data) {
        // console.log(data.id);
        this.recargarImagenesBottoms();
      }

    });


  }
  recargarImagenesBottoms() {
    this.loading = true;
    this.api.getListaImagenBottoms().subscribe(listaImagenesBottoms => {
      this.imagenesbottom = listaImagenesBottoms;
      this.loading = false;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  ngOnInit() {
  }
  eliminarImagenesBottom(id) {
    console.log(id);
    this.loading = true;
    this.api.deleteImagenBottom(id).subscribe(res => {
      this.loading = false;
      if (res !== 'success') {
        console.log(res);
      } else {
        this.recargarImagenesBottoms();
      }
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }
  getImageUrl(imagenBottom){
    return environment.baseUrl+'/img/imagenesbottom/'+imagenBottom.id+'.jpg';
  }
}
