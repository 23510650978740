import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IndexComponent } from './pages/index/index.component';
import { ListaCategoriasEmpleoComponent } from './pages/categoriasempleo/listacategoriasempleo/listacategoriasempleo.component';
import { FormCategoriasEmpleoComponent } from './pages/categoriasempleo/formcategoriasempleo/formcategoriasempleo.component';
import { ListaAdminComponent } from './pages/administradores/listaadmin/listaadmin.component';
import { FormAdminComponent } from './pages/administradores/formadmin/formadmin.component';
import { ListaciudadComponent } from './pages/ciudades/listaciudad/listaciudad.component';
import { FormciudadComponent } from './pages/ciudades/formciudad/formciudad.component';
import { ListaempresaComponent } from './pages/empresas/listaempresa/listaempresa.component';
import { FormempresaComponent } from './pages/empresas/formempresa/formempresa.component';
import { ListausuariosappComponent } from './pages/usuariosapp/listausuariosapp/listausuariosapp.component';
import { FormusuariosappComponent } from './pages/usuariosapp/formusuariosapp/formusuariosapp.component';
import { ListaofertasComponent } from './pages/ofertas/listaofertas/listaofertas.component';
import { FormofertasComponent } from './pages/ofertas/formofertas/formofertas.component';
import { ListaimagentopComponent } from './pages/imagentop/listaimagentop/listaimagentop.component';
import { FormimagentopComponent } from './pages/imagentop/formimagentop/formimagentop.component';
import { ListaimagenbottomComponent } from './pages/imagenbottom/listaimagenbottom/listaimagenbottom.component';
import { FormimagenbottomComponent } from './pages/imagenbottom/formimagenbottom/formimagenbottom.component';
import { ListahojasComponent } from './pages/hojasdevida/listahojas/listahojas.component';
import { ListacarrerasComponent } from './pages/carreras/listacarreras/listacarreras.component';
import { FormcarreraComponent } from './pages/carreras/formcarrera/formcarrera.component';
import { UnahojaComponent } from './pages/hojasdevida/unahoja/unahoja.component';
import { ListapostulantesComponent } from './pages/ofertas/listapostulantes/listapostulantes.component';


const routes: Routes = [
  { path: 'home', component: IndexComponent },
  { path: 'categoriasempleo', component: ListaCategoriasEmpleoComponent },
  { path: 'categoriasempleo/create', component: FormCategoriasEmpleoComponent },
  { path: 'categoriasempleo/:id', component: FormCategoriasEmpleoComponent },
  { path: 'administradores', component: ListaAdminComponent },
  { path: 'administradores/create', component: FormAdminComponent },
  { path: 'administradores/:id', component: FormAdminComponent },
  { path: 'ciudades', component: ListaciudadComponent },
  { path: 'ciudades/create', component: FormciudadComponent },
  { path: 'ciudades/:id', component: FormciudadComponent },
  { path: 'carreras', component: ListacarrerasComponent },
  { path: 'carreras/create', component: FormcarreraComponent },
  { path: 'carreras/:id', component: FormcarreraComponent },
  { path: 'empresas', component: ListaempresaComponent },
  { path: 'empresas/create', component: FormempresaComponent },
  { path: 'empresas/:id', component: FormempresaComponent },
  { path: 'usuariosapp', component: ListausuariosappComponent },
  { path: 'usuariosapp/create', component: FormusuariosappComponent },
  { path: 'usuariosapp/:id', component: FormusuariosappComponent },
  { path: 'ofertas', component: ListaofertasComponent },
  { path: 'ofertas/create', component: FormofertasComponent },
  { path: 'ofertas/postulantes/:id', component: ListapostulantesComponent },
  { path: 'ofertas/:id', component: FormofertasComponent },
  { path: 'imagenestop', component: ListaimagentopComponent },
  { path: 'imagenestop/create', component: FormimagentopComponent },
  { path: 'imagenestop/:id', component: FormimagentopComponent },
  { path: 'imagenesbottom', component: ListaimagenbottomComponent },
  { path: 'imagenesbottom/create', component: FormimagenbottomComponent },
  { path: 'imagenesbottom/:id', component: FormimagenbottomComponent },
  { path: 'curriculums', component: ListahojasComponent },
  { path: 'curriculums/:id', component: UnahojaComponent },
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  { path: '**', pathMatch: 'full', redirectTo: 'home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
