import { Component, OnInit } from '@angular/core';
import { ListaCurriculum, Curriculum } from '../../../models/DatosHojaDeVida';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { PostulacionesapiService } from '../../../services/postulacionesapi.service';
import { CiudadapiService } from '../../../services/ciudadapi.service';
import { Ciudad } from '../../../models/Ciudad';
import { Carrera } from 'src/app/models/Carrera';
import { CarreraapiService } from '../../../services/carreraapi.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-busquedahojasdevida',
  templateUrl: './busquedahojasdevida.component.html',
  styleUrls: ['./busquedahojasdevida.component.scss']
})
export class BusquedahojasdevidaComponent implements OnInit {
  curriculums: Curriculum[] = [];
  listaCiudades: Ciudad[] = [];
  listaCarreras: Carrera[] = [];
  searchModel = {
    residencia: '',
    nivelAcademico: '',
    carrera: '',
    edad: '',
    idioma: '',
    experiencia: '',
    contratado: false,
    favoritos: false,
    busqueda: ''
  };
  constructor(
    private api: PostulacionesapiService,
    private actRoute: ActivatedRoute,
    private ciudadesApi: CiudadapiService,
    private carreraApi: CarreraapiService
  ) {
    this.actRoute.params.subscribe(data => {
      if (data) {
        this.getCurriculums();
        this.getCiudades();
        this.getCarreras();
      }
    });
  }

  ngOnInit() {
  }
  getCiudades() {
    this.ciudadesApi.getListaCiudades().subscribe(data => {
      this.listaCiudades = data;
    });
  }
  getCurriculums() {
    this.api.getDatosCurriculum(this.searchModel).subscribe(data => {
      this.curriculums = data;
    });
  }
  getCarreras() {
    this.carreraApi.getListaCarreras().subscribe(data => {
      this.listaCarreras = data;
    });
  }
  marcarFavorito(id: number) {

  }
  searchNow() {
    this.getCurriculums();
  }
  getCurriculumImage(curriculum: Curriculum) {
      return `${environment.baseUrl}/img/curriculums/${curriculum.id}.jpeg`;

  }
  splitField(value: string){
    return value.split('|');
  }
}
