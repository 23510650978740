import { Injectable } from '@angular/core';
import { BaseService } from './baseapi.service';
import { UsuarioApp, UsuarioAppSingleResponse, UsuarioAppResponse } from '../models/UsuarioApp';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsuarioappapiService extends BaseService{

  
  createUsuarioApp(categoria: UsuarioApp) {
    return this.postBaseRequest<UsuarioAppSingleResponse>('api/usuariosapp', categoria)
      .pipe(map(data => data.data));
  }
  updateUsuarioApp(categoria: UsuarioApp) {
    return this.putBaseRequest<UsuarioAppSingleResponse>('api/usuariosapp/' + categoria.id, categoria)
      .pipe(map(data => data.data));
  }
  changePassword(newPassword: string, id: number) {
    return this.postBaseRequest<UsuarioAppSingleResponse>('api/usuariosapp/' + id + '/cp', {password: newPassword})
      .pipe(map(data => data.data));
  }
  deleteUsuarioApp(id) {
    return this.deleteBaseRequest<UsuarioAppSingleResponse>('api/usuariosapp/' + id)
      .pipe(map(data => data.res));
  }

  getListaUsuariosApp() {
    return this.getBaseRequest<UsuarioAppResponse>('api/usuariosapp')
      .pipe(map(data => data.data));
  }
  getUsuarioApp(id: string) {
    return this.getBaseRequest<UsuarioAppSingleResponse>('api/usuariosapp/' + id)
      .pipe(map(data => data.data));
  }
}
