import { Component, OnInit } from '@angular/core';
import { Oferta } from 'src/app/models/Oferta';
import { ActivatedRoute, Router } from '@angular/router';
import { OfertasapiService } from '../../../services/ofertasapi.service';
import { CiudadapiService } from '../../../services/ciudadapi.service';
import { EmpresaapiService } from '../../../services/empresaapi.service';
import { Ciudad } from 'src/app/models/Ciudad';
import { Empresa } from 'src/app/models/Empresa';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { CategoriasempleoapiService } from '../../../services/categoriasempleoapi.service';
import { CategoriaEmpleo } from 'src/app/models/CategoriaEmpleo';
import { formatDate } from '@angular/common';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-formofertas',
  templateUrl: './formofertas.component.html',
  styleUrls: ['./formofertas.component.scss']
})
export class FormofertasComponent implements OnInit {

  public DescripcionEditor = ClassicEditor;
  fechaInicioObject: NgbDateStruct;
  fechaFinObject: NgbDateStruct;
  currentDate = new Date();
  oferta: Oferta = {
    titulo: '',
    ciudad_id: -1,
    user_id: 0,
    fechaInicio: formatDate(this.currentDate, 'yyyy-MM-dd', 'en'),
    fechaFin:  formatDate(new Date(this.currentDate.setMonth(this.currentDate.getMonth() + 1)), 'yyyy-MM-dd', 'en'),
    descripcion: '',
    estado: '',
    id: 0
  };
  loading: boolean;
  error: boolean;
  ciudades: Ciudad[];
  empresas: Empresa[];
  filedata: any;
  myFile: any;
  categoriaEmpleos: CategoriaEmpleo[];
  constructor(
    private actRoute: ActivatedRoute,
    private api: OfertasapiService,
    private router: Router,
    private apiCiudades: CiudadapiService,
    private apiEmpresa: EmpresaapiService,
    private apiCategoriaEmpleo: CategoriasempleoapiService,
    private parserFormatter: NgbDateParserFormatter
  ) {
    actRoute.params.subscribe(data => {
      if (data.id) {
        // console.log(data.id);
        this.getOferta(data.id);

      } else {
        this.fechaInicioObject = this.parserFormatter.parse(this.oferta.fechaInicio);
        this.fechaFinObject = this.parserFormatter.parse(this.oferta.fechaFin);
        this.getCategoriasEmpleo();
      }
      this.getCiudades();
      this.getEmpresas();
    });
  }
  getCiudades() {
    this.apiCiudades.getListaCiudades().subscribe(listaCiudades => {
      this.ciudades = listaCiudades;
    });
  }
  getCategoriaName(objCategoria: CategoriaEmpleo) {

    return objCategoria.nombre + '-' + objCategoria.id;
  }
  getCategoriasEmpleo() {
    this.apiCategoriaEmpleo.getListaCategoriasEmpleo().subscribe(listaCategorias => {
      console.log(listaCategorias);
      this.categoriaEmpleos = listaCategorias.map(objCategoria => {
        // console.log("objCat",objCategoria);
        if (this.oferta.categoriasempleo != null) {
          const filterCategorias = this.oferta.categoriasempleo.filter(
            objCatEmpleo => { if (objCatEmpleo.id === objCategoria.id) { return objCatEmpleo; } }
          );
          // console.log("filtradas",filterCategorias);
          if (filterCategorias.length > 0) {
            objCategoria.selected = true;
            return objCategoria;
          }
        }
        objCategoria.selected = false;
        return objCategoria;
      });
      // console.log(this.categoriaEmpleos);
    });
  }
  async getEmpresas() {
    this.empresas = await this.apiEmpresa.getListaEmpresas().toPromise();
  }
  getOferta(id: string) {
    this.api.getOferta(id).subscribe(data => {
      if (data) {
        this.oferta = data;
        this.fechaInicioObject = this.parserFormatter.parse(this.oferta.fechaInicio);
        this.fechaFinObject = this.parserFormatter.parse(this.oferta.fechaFin);
        this.error = false;
        this.getCategoriasEmpleo();
      } else {
        this.error = true;
      }
      // console.log(data);

      this.loading = false;
    });
  }

  ngOnInit() {
  }
  guardarDatos() {
    console.log(this.fechaInicioObject);
    console.log(this.fechaFinObject);
    this.oferta.fechaInicio = this.parserFormatter.format(this.fechaInicioObject);
    this.oferta.fechaFin = this.parserFormatter.format(this.fechaFinObject);
    this.oferta.categoriasSeleccionadas = this.categoriaEmpleos.map(objCat => {
      if (objCat.selected) {
        return objCat.id;
      }
      return 0;
    }).filter(id => { if (id > 0) { return id; } });
    // TODO: Validar datos
    console.log('oferta', this.oferta);

    // TODO: Agarrar datos de la sesión
    this.oferta.user_id = 1;
    if (this.oferta.id === 0) {
      this.api.createOferta(this.oferta).subscribe(data => {
        console.log(data);
        this.oferta = data;
        this.enviarImagen();
      }, (error) => {
        console.log(error);
        this.loading = false;
      });
    } else {
      this.api.updateOferta(this.oferta).subscribe(data => {
        this.oferta = data;
        this.enviarImagen();
      }, (error) => {
        console.log(error);
        this.loading = false;
      });
    }
  }
  fileEvent(e) {
    this.filedata = e.target.files[0];
  }
  enviarImagen() {
    if (this.filedata) {
      const myFormData = new FormData();
      myFormData.append('image', this.filedata);
      console.log(this.filedata);
      this.api.enviarFoto(myFormData, this.oferta.id).subscribe(data => {
        if (data) {
          console.log('foto enviada correctamente');
        }
        this.loading = false;
        this.redirectToList();
      }, (error) => {
        console.log(error);
        this.loading = false;
      });
    } else {
      this.loading = false;
      this.redirectToList();
    }
  }
  redirectToList() {
    this.router.navigateByUrl('/ofertas');
  }
  getImageUrl() {
    return environment.baseUrl + '/img/postulaciones/' + this.oferta.id + '.jpg?rand=' + Math.random();
  }
}
