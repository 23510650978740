import { Injectable } from '@angular/core';
import { BaseService } from './baseapi.service';
import { ListaDatosCiudades, ListaDatosCarrera, ListaDatosPiechart, ListaCurriculum, SingleCurriculumResponse } from '../models/DatosHojaDeVida';
import { map } from 'rxjs/operators';
import { PostulacionResponse } from '../models/Postulacion';

@Injectable({
  providedIn: 'root'
})
export class PostulacionesapiService extends BaseService {
  getDatosCarreras() {
    return this.getBaseRequest<ListaDatosCarrera>('api/postulaciones/carreras')
      .pipe(map(data => data));
  }
  getDatosCiudades() {
    return this.getBaseRequest<ListaDatosCiudades>('api/postulaciones/datosciudades')
      .pipe(map(data => data));
  }
  getDatosNivelAcademico() {
    return this.getBaseRequest<ListaDatosPiechart>('api/postulaciones/nivelacademico')
      .pipe(map(data => data));
  }
  getDatosExperienciaLaboral() {
    return this.getBaseRequest<ListaDatosPiechart>('api/postulaciones/datosexperiencialaboral')
      .pipe(map(data => data));
  }
  getDatosCurriculum(params) {
    return this.postBaseRequest<ListaCurriculum>('api/postulaciones/datoscurriculum', params)
      .pipe(map(data => data.datos));

  }
  getUnCurriculum(id) {
    return this.getBaseRequest<SingleCurriculumResponse>('api/usuariosapp/' + id + '/curriculumdata')
      .pipe(map(data => data.data));

  }
  getListaPostulaciones(idOferta) {
    return this.getBaseRequest<PostulacionResponse>('api/postulaciones/' + idOferta)
      .pipe(map(data => data.data));
  }
}
