import { Component, OnInit } from '@angular/core';
import { PostulacionesapiService } from '../../../services/postulacionesapi.service';
import { ActivatedRoute } from '@angular/router';
import { ListaDatosPiechart } from 'src/app/models/DatosHojaDeVida';

@Component({
  selector: 'app-listahojas',
  templateUrl: './listahojas.component.html',
  styleUrls: ['./listahojas.component.scss']
})
export class ListahojasComponent implements OnInit {
  dataNivelAcademico: ListaDatosPiechart;
  dataExperienciaLaboral: ListaDatosPiechart;
  constructor(
    private api: PostulacionesapiService,
    private actRoute: ActivatedRoute
  ) {

    actRoute.params.subscribe(data => {
      if (data) {
        this.getDatosNivelAcademico();
        this.getDatosExperienciaLaboral();
      }
    });
  }
  ngOnInit() {
  }

  getDatosExperienciaLaboral() {
    this.api.getDatosExperienciaLaboral().subscribe(data => {
      this.dataExperienciaLaboral = data;
    });

  }
  getDatosNivelAcademico() {
    this.api.getDatosNivelAcademico().subscribe(data => {
      this.dataNivelAcademico = data;
    });

  }

}
