import { Component, OnInit } from '@angular/core';
import { ListaDatosCarrera } from '../../../models/DatosHojaDeVida';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { PostulacionesapiService } from '../../../services/postulacionesapi.service';

@Component({
  selector: 'app-listacarrerascv',
  templateUrl: './listacarrerascv.component.html',
  styleUrls: ['./listacarrerascv.component.scss']
})
export class ListacarrerascvComponent implements OnInit {
  datosCarreras: ListaDatosCarrera;
  constructor(
    private actRoute: ActivatedRoute,
    private api: PostulacionesapiService
  ) {
    this.actRoute.params.subscribe(data => {
      if (data) {
        this.getListaCarreras();
      }
    });
  }

  ngOnInit() {
  }
  getListaCarreras() {
    this.api.getDatosCarreras().subscribe(data => {
      this.datosCarreras = data;
    });
  }

}
