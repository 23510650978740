import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { OfertasapiService } from 'src/app/services/ofertasapi.service';
import { Oferta } from 'src/app/models/Oferta';
import { ActivatedRoute } from '@angular/router';
import { UsuarioappapiService } from 'src/app/services/usuarioappapi.service';
import { UsuarioApp } from 'src/app/models/UsuarioApp';
import { Postulacion } from '../../../models/Postulacion';
import { PostulacionesapiService } from '../../../services/postulacionesapi.service';
import { Curriculum } from 'src/app/models/DatosHojaDeVida';

@Component({
  selector: 'app-listapostulantes',
  templateUrl: './listapostulantes.component.html',
  styleUrls: ['./listapostulantes.component.scss']
})
export class ListapostulantesComponent implements OnInit {


  postulaciones: Postulacion[] = [];
  loading: boolean;
  constructor(private api: PostulacionesapiService, private actRoute: ActivatedRoute) {
    // constructor(){
    actRoute.params.subscribe(data => {
      if (data) {
        // console.log(data.id);
        this.recargarOfertas(data.id);
      }

    });


  }
  
  recargarOfertas(idOferta) {
    this.loading = true;
    this.api.getListaPostulaciones(idOferta).subscribe(listaPostulaciones => {
      this.postulaciones = listaPostulaciones;
      this.loading = false;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  ngOnInit() {
  }
  getCurriculumImage(curriculum: Curriculum) {
    return `${environment.baseUrl}/img/curriculums/${curriculum.id}.jpeg`;
}
}
