import { Component, OnInit } from '@angular/core';
import { ImagenTop } from 'src/app/models/Imagentop';
import { ActivatedRoute, Router } from '@angular/router';
import { ImagenestopapiService } from 'src/app/services/imagenestopapi.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-formimagentop',
  templateUrl: './formimagentop.component.html',
  styleUrls: ['./formimagentop.component.scss']
})
export class FormimagentopComponent implements OnInit {

  imagentop: ImagenTop = { nombre: '', orden: 0, descripcion: '', id: 0 };
  loading: boolean;
  error: boolean;
  filedata: any;
  myFile: any;
  constructor(private actRoute: ActivatedRoute, private api: ImagenestopapiService, private router: Router) {
    actRoute.params.subscribe(data => {
      if (data.id) {
        // console.log(data.id);
        this.getImagenTop(data.id);
      }

    });
  }
  fileEvent(e) {
    this.filedata = e.target.files[0];
  }
  enviarImagen() {
    if (this.filedata) {
      const myFormData = new FormData();
      myFormData.append('image', this.filedata);
      console.log(this.filedata);
      this.api.enviarFoto(myFormData, this.imagentop.id).subscribe(data => {
        if (data) {
          console.log('foto enviada correctamente');
        }
        this.loading = false;
        this.redirectToList();
      }, (error) => {
        console.log(error);
        this.loading = false;
      });
    } else {
      this.loading = false;
      this.redirectToList();
    }
  }
  getImagenTop(id: string) {
    this.api.getImagenTop(id).subscribe(data => {
      if (data) {
        this.imagentop = data;
        this.error = false;
      } else {
        this.error = true;
      }
      // console.log(data);

      this.loading = false;
    });
  }

  ngOnInit() {
  }
  guardarDatos() {
    console.log(this.imagentop);
    if (this.imagentop.id === 0) {
      this.api.createImagenTop(this.imagentop).subscribe(data => {
        this.loading = false;
        this.imagentop = data;
        this.enviarImagen();
      }, (error) => {
        console.log(error);
        this.loading = false;
      });
    } else {
      this.api.updateImagenTop(this.imagentop).subscribe(data => {
        this.loading = false;
        this.imagentop = data;
        this.enviarImagen();
      }, (error) => {
        console.log(error);
        this.loading = false;
      });
    }
  }
  redirectToList() {
    this.router.navigateByUrl('/imagenestop');
  }
  getImageUrl(imagenTop){
    return environment.baseUrl+'/img/imagenestop/'+imagenTop.id+'.jpg';
  }
}
