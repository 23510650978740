import { Component, OnInit, Input } from '@angular/core';
import { DatoCiudad } from '../../../models/DatosHojaDeVida';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-undepartamento',
  templateUrl: './undepartamento.component.html',
  styleUrls: ['./undepartamento.component.scss']
})
export class UndepartamentoComponent implements OnInit {

  @Input() ciudad: DatoCiudad;

  constructor() { }

  ngOnInit() {
  }
  getImageUrl() {
    return `${environment.baseUrl}/img/ciudad/${this.ciudad.id}.jpg`;
  }
}
