import { Component, OnInit } from '@angular/core';
import { UsuarioApp } from 'src/app/models/UsuarioApp';
import { ActivatedRoute, Router } from '@angular/router';
import { UsuarioappapiService } from 'src/app/services/usuarioappapi.service';
import * as CryptoJS from 'crypto-js';
import { SHA1 } from 'crypto-js';

@Component({
  selector: 'app-formusuariosapp',
  templateUrl: './formusuariosapp.component.html',
  styleUrls: ['./formusuariosapp.component.scss']
})
export class FormusuariosappComponent implements OnInit {
  userId: number;
  passworddata = {
    password: '',
    confirmpassword: ''
  };

  loading: boolean;
  error: boolean;
  constructor(private actRoute: ActivatedRoute, private api: UsuarioappapiService, private router: Router) {
    actRoute.params.subscribe(data => {
      if (data.id) {
        this.userId = data.id;
      }

    });
  }


  ngOnInit() {
  }
  guardarDatos() {
    if (this.userId !== 0) {
      const passwordBytes = CryptoJS.enc.Utf16LE.parse(this.passworddata.password);
      const sha1Hash = CryptoJS.SHA1(passwordBytes);
      console.log(sha1Hash.toString());
      this.api.changePassword(sha1Hash.toString(), this.userId).subscribe(data => {
        this.loading = false;
        this.router.navigateByUrl('/usuariosapp');
      }, (error) => {
        console.log(error);
        this.loading = false;
      });
    }
  }


}
