import { Component, OnInit } from '@angular/core';
import { Ciudad } from 'src/app/models/Ciudad';
import { CiudadapiService } from 'src/app/services/ciudadapi.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-listaciudad',
  templateUrl: './listaciudad.component.html',
  styleUrls: ['./listaciudad.component.scss']
})
export class ListaciudadComponent implements OnInit {

  ciudades: Ciudad[] = [];
  loading: boolean;
  constructor(private api: CiudadapiService, private actRoute: ActivatedRoute) {
    // constructor(){
    actRoute.params.subscribe(data => {
      if (data) {
        // console.log(data.id);
        this.recargarCiudades();
      }

    });


  }
  recargarCiudades() {
    this.loading = true;
    this.api.getListaCiudades().subscribe(listaCiudades => {
      this.ciudades = listaCiudades;
      this.loading = false;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  ngOnInit() {
  }
  eliminarCiudad(id) {
    console.log(id);
    this.loading = true;
    this.api.deleteCiudad(id).subscribe(res => {
      this.loading = false;
      if (res !== 'success') {
        console.log(res);
      } else {
        this.recargarCiudades();
      }
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

}
