import { Component, OnInit } from '@angular/core';
import { Empresa } from 'src/app/models/Empresa';
import { ActivatedRoute, Router } from '@angular/router';
import { EmpresaapiService } from 'src/app/services/empresaapi.service';

@Component({
  selector: 'app-formempresa',
  templateUrl: './formempresa.component.html',
  styleUrls: ['./formempresa.component.scss']
})
export class FormempresaComponent implements OnInit {

  empresa: Empresa = { nombre: '', id: 0 };
  loading: boolean;
  error: boolean;
  constructor(private actRoute: ActivatedRoute, private api: EmpresaapiService, private router: Router) {
    actRoute.params.subscribe(data => {
      if (data.id) {
        // console.log(data.id);
        this.getEmpresa(data.id);
      }

    });
  }

  getEmpresa(id: string) {
    this.api.getEmpresa(id).subscribe(data => {
      if (data) {
        this.empresa = data;
        this.error = false;
      } else {
        this.error = true;
      }
      // console.log(data);

      this.loading = false;
    });
  }

  ngOnInit() {
  }
  guardarDatos() {
    console.log(this.empresa);
    if (this.empresa.id === 0) {
      this.api.createEmpresa(this.empresa).subscribe(listaEmpresas => {
        this.loading = false;
        console.log('inserta');
        console.log('navega');
        this.router.navigateByUrl('/empresas');
      }, (error) => {
        console.log(error);
        this.loading = false;
      });
    } else {
      this.api.updateEmpresa(this.empresa).subscribe(listaEmpresas => {
        console.log('actualiza');
        this.loading = false;
        console.log('navega');
        this.router.navigateByUrl('/empresas');
      }, (error) => {
        console.log(error);
        this.loading = false;
      });
    }
  }

}
