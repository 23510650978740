import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Administrador } from 'src/app/models/Administrador';
import { AdministradorapiService } from 'src/app/services/administradorapi.service';

@Component({
  selector: 'app-form-admin',
  templateUrl: './formadmin.component.html',
  styleUrls: ['./formadmin.component.scss']
})
export class FormAdminComponent implements OnInit {
  administrador: Administrador = { name: '', id: 0, email: '', password: '' };
  loading: boolean;
  error: boolean;
  constructor(private actRoute: ActivatedRoute, private api: AdministradorapiService, private router: Router) {
    actRoute.params.subscribe(data => {
      if (data.id) {
        // console.log(data.id);
        this.getAdministrador(data.id);
      }

    });
  }

  getAdministrador(id: string) {
    this.api.getAdministrador(id).subscribe(data => {
      if (data) {
        this.administrador = data;
        this.error = false;
      } else {
        this.error = true;
      }
      // console.log(data);

      this.loading = false;
    });
  }

  ngOnInit() {
  }
  guardarDatos() {
    // console.log(this.administrador);
    if (this.administrador.id === 0) {
      this.api.createAdministrador(this.administrador).subscribe(listaAdministradors => {
        this.loading = false;
        console.log('inserta');
        console.log('navega');
        this.router.navigateByUrl('/administradores');
      }, (error) => {
        console.log(error);
        this.loading = false;
      });
    } else {
      this.api.updateAdministrador(this.administrador).subscribe(listaAdministradors => {
        console.log('actualiza');
        this.loading = false;
        console.log('navega');
        this.router.navigateByUrl('/administradores');
      }, (error) => {
        console.log(error);
        this.loading = false;
      });
    }
  }

}
