import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './baseapi.service';
import { map } from 'rxjs/operators';
import { CategoriaEmpleo, CategoriaEmpleoResponse, CategoriaEmpleoSingleResponse } from '../models/CategoriaEmpleo';
@Injectable({
  providedIn: 'root'
})
export class CategoriasempleoapiService extends BaseService {
  createCategoria(categoria: CategoriaEmpleo) {
    return this.postBaseRequest<CategoriaEmpleoSingleResponse>('api/categoriasempleo', categoria)
      .pipe(map(data => data.data));
  }
  updateCategoria(categoria: CategoriaEmpleo) {
    return this.putBaseRequest<CategoriaEmpleoSingleResponse>('api/categoriasempleo/' + categoria.id, categoria)
      .pipe(map(data => data.data));
  }
  deleteCategoria(id) {
    return this.deleteBaseRequest<CategoriaEmpleoSingleResponse>('api/categoriasempleo/' + id)
      .pipe(map(data => data.res));
  }


  getListaCategoriasEmpleo() {
    return this.getBaseRequest<CategoriaEmpleoResponse>('api/categoriasempleo')
      .pipe(map(data => data.data));
  }
  getCategoria(id: string) {
    return this.getBaseRequest<CategoriaEmpleoSingleResponse>('api/categoriasempleo/' + id)
      .pipe(map(data => data.data));
  }

}
