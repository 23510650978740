import { Component, OnInit } from '@angular/core';
import { DatoCiudad, ListaDatosCiudades } from '../../../models/DatosHojaDeVida';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { CiudadapiService } from '../../../services/ciudadapi.service';
import { PostulacionesapiService } from '../../../services/postulacionesapi.service';

@Component({
  selector: 'app-listaciudades',
  templateUrl: './listaciudades.component.html',
  styleUrls: ['./listaciudades.component.scss']
})
export class ListaciudadesComponent implements OnInit {
  ciudades: ListaDatosCiudades;
  constructor(
    private actRoute: ActivatedRoute,
    private api: PostulacionesapiService
  ) {
    actRoute.params.subscribe(data => {
      if (data) {
        this.cargarListaCiudades();
      }
    });
  }

  ngOnInit() {
  }
  cargarListaCiudades() {
    this.api.getDatosCiudades().subscribe(data => {
      this.ciudades = data;
    });
  }
  getTotal() {
    const result: DatoCiudad = {
      id: 0,
      nombre: 'Total',
      total: this.ciudades.total
    };
    return result;
  }
}
