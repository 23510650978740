import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoriasempleoapiService } from '../../../services/categoriasempleoapi.service';
import { CategoriaEmpleo } from '../../../models/CategoriaEmpleo';

@Component({
  selector: 'app-form-categoriasempleo',
  templateUrl: './formcategoriasempleo.component.html',
  styleUrls: ['./formcategoriasempleo.component.scss']
})
export class FormCategoriasEmpleoComponent implements OnInit {
  categoria: CategoriaEmpleo = { nombre: '', id: 0 };
  loading: boolean;
  error: boolean;
  constructor(private actRoute: ActivatedRoute, private api: CategoriasempleoapiService, private router: Router) {
    actRoute.params.subscribe(data => {
      if (data.id) {
        // console.log(data.id);
        this.getCategoria(data.id);
      }

    });
  }

  getCategoria(id: string) {
    this.api.getCategoria(id).subscribe(data => {
      if (data) {
        this.categoria = data;
        this.error = false;
      } else {
        this.error = true;
      }
      // console.log(data);

      this.loading = false;
    });
  }

  ngOnInit() {
  }
  guardarDatos() {
    // console.log(this.categoria);
    if (this.categoria.id === 0) {
      this.api.createCategoria(this.categoria).subscribe(listaCategorias => {
        this.loading = false;
        console.log('inserta');
        console.log('navega');
        this.router.navigateByUrl('/categoriasempleo');
      }, (error) => {
        console.log(error);
        this.loading = false;
      });
    } else {
      this.api.updateCategoria(this.categoria).subscribe(listaCategorias => {
        console.log('actualiza');
        this.loading = false;
        console.log('navega');
        this.router.navigateByUrl('/categoriasempleo');
      }, (error) => {
        console.log(error);
        this.loading = false;
      });
    }
  }
}
