import { Injectable } from '@angular/core';
import { BaseService } from './baseapi.service';
import { CiudadSingleResponse, Ciudad, CiudadResponse } from '../models/Ciudad';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CiudadapiService extends BaseService {

  createCiudad(categoria: Ciudad) {
    return this.postBaseRequest<CiudadSingleResponse>('api/ciudades', categoria)
      .pipe(map(data => data.data));
  }
  updateCiudad(categoria: Ciudad) {
    return this.putBaseRequest<CiudadSingleResponse>('api/ciudades/' + categoria.id, categoria)
      .pipe(map(data => data.data));
  }
  deleteCiudad(id) {
    return this.deleteBaseRequest<CiudadSingleResponse>('api/ciudades/' + id)
      .pipe(map(data => data.res));
  }

  getListaCiudades() {
    return this.getBaseRequest<CiudadResponse>('api/ciudades')
      .pipe(map(data => data.data));
  }
  getCiudad(id: string) {
    return this.getBaseRequest<CiudadSingleResponse>('api/ciudades/' + id)
      .pipe(map(data => data.data));
  }
}
