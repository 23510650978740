import { Injectable } from '@angular/core';
import { BaseService } from './baseapi.service';
import { map } from 'rxjs/operators';
import { Empresa, EmpresaSingleResponse, EmpresaResponse } from '../models/Empresa';

@Injectable({
  providedIn: 'root'
})
export class EmpresaapiService extends BaseService {


  createEmpresa(categoria: Empresa) {
    return this.postBaseRequest<EmpresaSingleResponse>('api/empresas', categoria)
      .pipe(map(data => data.data));
  }
  updateEmpresa(categoria: Empresa) {
    return this.putBaseRequest<EmpresaSingleResponse>('api/empresas/' + categoria.id, categoria)
      .pipe(map(data => data.data));
  }
  deleteEmpresa(id) {
    return this.deleteBaseRequest<EmpresaSingleResponse>('api/empresas/' + id)
      .pipe(map(data => data.res));
  }

  getListaEmpresas() {
    return this.getBaseRequest<EmpresaResponse>('api/empresas')
      .pipe(map(data => data.data));
  }
  getEmpresa(id: string) {
    return this.getBaseRequest<EmpresaSingleResponse>('api/empresas/' + id)
      .pipe(map(data => data.data));
  }
}
