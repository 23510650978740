import { Component, OnInit, Input } from '@angular/core';
import { ListaDatosPiechart } from '../../../models/DatosHojaDeVida';
import { ChartType, ChartOptions } from 'chart.js';
import { SingleDataSet, Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';
import { empty } from 'rxjs';
import * as Chart from 'chart.js';

@Component({
  selector: 'app-unpiechart',
  templateUrl: './unpiechart.component.html',
  styleUrls: ['./unpiechart.component.scss']
})
export class UnpiechartComponent implements OnInit {
  @Input() datos: ListaDatosPiechart;
  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'bottom',
      labels: {
        generateLabels(chart) {
          const data = chart.data;
          if (data.labels.length && data.datasets.length) {
            return data.labels.map((label, i) => {
              const meta = chart.getDatasetMeta(0);
              const ds = data.datasets[0];
              const arc = meta.data[i];
              const custom = arc && arc.custom || {};
              const getValueAtIndexOrDefault = Chart.helpers.getValueAtIndexOrDefault;
              const arcOpts = chart.options.elements.arc;
              const fill = custom.backgroundColor ? custom.backgroundColor :
                getValueAtIndexOrDefault(ds.backgroundColor, i, arcOpts.backgroundColor);
              const stroke = custom.borderColor ? custom.borderColor : getValueAtIndexOrDefault(ds.borderColor, i, arcOpts.borderColor);
              const bw = custom.borderWidth ? custom.borderWidth : getValueAtIndexOrDefault(ds.borderWidth, i, arcOpts.borderWidth);

              // We get the value of the current label
              const value = chart.config.data.datasets[arc._datasetIndex].data[arc._index];

              return {
                // Instead of `text: label,`
                // We add the value to the string
                text: value + ' : ' + label,
                fillStyle: fill,
                strokeStyle: stroke,
                lineWidth: bw,
                hidden: isNaN(parseFloat(ds.data[i].toString())) || meta.data[i].hidden,
                index: i
              };
            });
          } else {
            return [];
          }
        }
      }
    },
    tooltips: {
      enabled: true
    }
  };
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [];

  constructor() {
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
  }

  ngOnInit() {
  }
  getPieChartData() {
    if (!this.datos) {
      return null;
    }
    const data: SingleDataSet = this.datos.datos.map(obj => obj.total);
    return data;
  }
  getPieChartLabels() {
    if (!this.datos) {
      const emptyLabel: Label[] = [];
      return emptyLabel;
    }
    const labels: Label[] = this.datos.datos.map(obj => [...obj.nombre.split(' ')]);
    return labels;
  }
}
