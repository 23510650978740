import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';


export class BaseService {
    constructor(protected http: HttpClient) { }
    protected getBaseRequest<E>(query: string) {
        const url = `${environment.baseUrl}/${query}`;

        // const headers = new HttpHeaders({
        // Authorization: 'Bearer BQCFPE6IJTOtqsHGJ11mf_nMLzwKgmT-apWhfX4AsR6NXWxuJ1bxYoQ-0AgDhI26XihInihYQRR1BLKxuLE'
        // });

        return this.http.get<E>(url);
    }
    protected postFileRequest<E>(query: string, body: any) {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        const url = `${environment.baseUrl}/${query}`;
        return this.http.post<E>(url, body, { headers });
    }
    protected postBaseRequest<E>(query: string, body: any) {
        const url = `${environment.baseUrl}/${query}`;
        return this.http.post<E>(url, body);
    }
    protected putBaseRequest<E>(query: string, body: any) {
        const url = `${environment.baseUrl}/${query}`;
        return this.http.put<E>(url, body);
    }
    protected deleteBaseRequest<E>(query: string) {
        const url = `${environment.baseUrl}/${query}`;
        return this.http.delete<E>(url);
    }
}
