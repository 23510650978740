import { Component, OnInit } from '@angular/core';
import { Carrera } from 'src/app/models/Carrera';
import { ActivatedRoute, Router } from '@angular/router';
import { CarreraapiService } from 'src/app/services/carreraapi.service';

@Component({
  selector: 'app-formcarrera',
  templateUrl: './formcarrera.component.html',
  styleUrls: ['./formcarrera.component.scss']
})
export class FormcarreraComponent implements OnInit {
  carrera: Carrera = { nombre: '', id: 0 };
  loading: boolean;
  error: boolean;
  constructor(private actRoute: ActivatedRoute, private api: CarreraapiService, private router: Router) {
    actRoute.params.subscribe(data => {
      if (data.id) {
        // console.log(data.id);
        this.getCarrera(data.id);
      }

    });
  }

  getCarrera(id: string) {
    this.api.getCarrera(id).subscribe(data => {
      if (data) {
        this.carrera = data;
        this.error = false;
      } else {
        this.error = true;
      }
      // console.log(data);

      this.loading = false;
    });
  }

  ngOnInit() {
  }
  guardarDatos() {
    console.log(this.carrera);
    if (this.carrera.id === 0) {
      this.api.createCarrera(this.carrera).subscribe(listaCarreras => {
        this.loading = false;
        this.router.navigateByUrl('/carreras');
      }, (error) => {
        this.loading = false;
      });
    } else {
      this.api.updateCarrera(this.carrera).subscribe(listaCarreras => {
        this.loading = false;
        this.router.navigateByUrl('/carreras');
      }, (error) => {
        console.log(error);
        this.loading = false;
      });
    }
  }

}
