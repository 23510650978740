import { Component, OnInit } from '@angular/core';
import { Ciudad } from 'src/app/models/Ciudad';
import { ActivatedRoute, Router } from '@angular/router';
import { CiudadapiService } from 'src/app/services/ciudadapi.service';

@Component({
  selector: 'app-formciudad',
  templateUrl: './formciudad.component.html',
  styleUrls: ['./formciudad.component.scss']
})
export class FormciudadComponent implements OnInit {
  ciudad: Ciudad = { nombre: '', id: 0 };
  loading: boolean;
  error: boolean;
  constructor(private actRoute: ActivatedRoute, private api: CiudadapiService, private router: Router) {
    actRoute.params.subscribe(data => {
      if (data.id) {
        // console.log(data.id);
        this.getCiudad(data.id);
      }

    });
  }

  getCiudad(id: string) {
    this.api.getCiudad(id).subscribe(data => {
      if (data) {
        this.ciudad = data;
        this.error = false;
      } else {
        this.error = true;
      }
      // console.log(data);

      this.loading = false;
    });
  }

  ngOnInit() {
  }
  guardarDatos() {
    console.log(this.ciudad);
    if (this.ciudad.id === 0) {
      this.api.createCiudad(this.ciudad).subscribe(listaCiudades => {
        this.loading = false;
        console.log('inserta');
        console.log('navega');
        this.router.navigateByUrl('/ciudades');
      }, (error) => {
        console.log(error);
        this.loading = false;
      });
    } else {
      this.api.updateCiudad(this.ciudad).subscribe(listaCiudades => {
        console.log('actualiza');
        this.loading = false;
        console.log('navega');
        this.router.navigateByUrl('/ciudades');
      }, (error) => {
        console.log(error);
        this.loading = false;
      });
    }
  }
}
