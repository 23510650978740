import { Injectable } from '@angular/core';
import { BaseService } from './baseapi.service';
import { CarreraSingleResponse, Carrera, CarreraResponse } from '../models/Carrera';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CarreraapiService extends BaseService {

  createCarrera(categoria: Carrera) {
    return this.postBaseRequest<CarreraSingleResponse>('api/carreras', categoria)
      .pipe(map(data => data.data));
  }
  updateCarrera(categoria: Carrera) {
    return this.putBaseRequest<CarreraSingleResponse>('api/carreras/' + categoria.id, categoria)
      .pipe(map(data => data.data));
  }
  deleteCarrera(id) {
    return this.deleteBaseRequest<CarreraSingleResponse>('api/carreras/' + id)
      .pipe(map(data => data.res));
  }

  getListaCarreras() {
    return this.getBaseRequest<CarreraResponse>('api/carreras')
      .pipe(map(data => data.data));
  }
  getCarrera(id: string) {
    return this.getBaseRequest<CarreraSingleResponse>('api/carreras/' + id)
      .pipe(map(data => data.data));
  }
}
