import { Component, OnInit } from '@angular/core';
import { UsuarioApp } from '../../../models/UsuarioApp';
import { UsuarioappapiService } from 'src/app/services/usuarioappapi.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-listausuariosapp',
  templateUrl: './listausuariosapp.component.html',
  styleUrls: ['./listausuariosapp.component.scss']
})
export class ListausuariosappComponent implements OnInit {


  usuariosapp: UsuarioApp[] = [];
  loading: boolean;
  constructor(private api: UsuarioappapiService, private actRoute: ActivatedRoute) {
    // constructor(){
    actRoute.params.subscribe(data => {
      if (data) {
        // console.log(data.id);
        this.recargarUsuariosapp();
      }

    });


  }
  recargarUsuariosapp() {
    this.loading = true;
    this.api.getListaUsuariosApp().subscribe(listaUsuariosapp => {
      this.usuariosapp = listaUsuariosapp;
      this.loading = false;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  ngOnInit() {
  }
  eliminarUsuarioapp(id) {
    console.log(id);
    this.loading = true;
    this.api.deleteUsuarioApp(id).subscribe(res => {
      this.loading = false;
      if (res !== 'success') {
        console.log(res);
      } else {
        this.recargarUsuariosapp();
      }
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }


}
