import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { ListaAdminComponent } from './pages/administradores/listaadmin/listaadmin.component';
import { FormAdminComponent } from './pages/administradores/formadmin/formadmin.component';
import { FormCategoriasEmpleoComponent } from './pages/categoriasempleo/formcategoriasempleo/formcategoriasempleo.component';
import { ListaCategoriasEmpleoComponent } from './pages/categoriasempleo/listacategoriasempleo/listacategoriasempleo.component';
import { IndexComponent } from './pages/index/index.component';
import { HttpClientModule } from '@angular/common/http';

import { FormciudadComponent } from './pages/ciudades/formciudad/formciudad.component';
import { ListaciudadComponent } from './pages/ciudades/listaciudad/listaciudad.component';
import { FormempresaComponent } from './pages/empresas/formempresa/formempresa.component';
import { ListaempresaComponent } from './pages/empresas/listaempresa/listaempresa.component';
import { FormusuariosappComponent } from './pages/usuariosapp/formusuariosapp/formusuariosapp.component';
import { ListausuariosappComponent } from './pages/usuariosapp/listausuariosapp/listausuariosapp.component';
import { FormofertasComponent } from './pages/ofertas/formofertas/formofertas.component';
import { ListaofertasComponent } from './pages/ofertas/listaofertas/listaofertas.component';
import { FormimagentopComponent } from './pages/imagentop/formimagentop/formimagentop.component';
import { ListaimagentopComponent } from './pages/imagentop/listaimagentop/listaimagentop.component';
import { ListaimagenbottomComponent } from './pages/imagenbottom/listaimagenbottom/listaimagenbottom.component';
import { FormimagenbottomComponent } from './pages/imagenbottom/formimagenbottom/formimagenbottom.component';
import { NgbDate, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ListahojasComponent } from './pages/hojasdevida/listahojas/listahojas.component';
import { UndepartamentoComponent } from './components/hojasdevida/undepartamento/undepartamento.component';
import { ListaciudadesComponent } from './components/hojasdevida/listaciudades/listaciudades.component';
import { MenuComponent } from './components/common/menu/menu.component';
import { ListacarrerascvComponent } from './components/hojasdevida/listacarrerascv/listacarrerascv.component';
import { UnacarreraComponent } from './components/hojasdevida/unacarrera/unacarrera.component';
import { UnpiechartComponent } from './components/hojasdevida/unpiechart/unpiechart.component';
import { BusquedahojasdevidaComponent } from './components/hojasdevida/busquedahojasdevida/busquedahojasdevida.component';
import { ChartsModule } from 'ng2-charts';
import { ListacarrerasComponent } from './pages/carreras/listacarreras/listacarreras.component';
import { FormcarreraComponent } from './pages/carreras/formcarrera/formcarrera.component';
import { UnahojaComponent } from './pages/hojasdevida/unahoja/unahoja.component';
import { ListapostulantesComponent } from './pages/ofertas/listapostulantes/listapostulantes.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ListaAdminComponent,
    FormAdminComponent,
    FormCategoriasEmpleoComponent,
    ListaCategoriasEmpleoComponent,
    IndexComponent,
    MenuComponent,
    FormciudadComponent,
    ListaciudadComponent,
    FormempresaComponent,
    ListaempresaComponent,
    FormusuariosappComponent,
    ListausuariosappComponent,
    FormofertasComponent,
    ListaofertasComponent,
    FormimagentopComponent,
    ListaimagentopComponent,
    ListaimagenbottomComponent,
    FormimagenbottomComponent,
    ListahojasComponent,
    UndepartamentoComponent,
    ListaciudadesComponent,
    ListacarrerascvComponent,
    UnacarreraComponent,
    UnpiechartComponent,
    BusquedahojasdevidaComponent,
    ListacarrerasComponent,
    FormcarreraComponent,
    UnahojaComponent,
    ListapostulantesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    CKEditorModule,
    ChartsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
