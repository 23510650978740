import { Component, OnInit } from '@angular/core';
import { Oferta } from 'src/app/models/Oferta';
import { ActivatedRoute } from '@angular/router';
import { OfertasapiService } from '../../../services/ofertasapi.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-listaofertas',
  templateUrl: './listaofertas.component.html',
  styleUrls: ['./listaofertas.component.scss']
})
export class ListaofertasComponent implements OnInit {


  ofertas: Oferta[] = [];
  loading: boolean;
  constructor(private api: OfertasapiService, private actRoute: ActivatedRoute) {
    // constructor(){
    actRoute.params.subscribe(data => {
      if (data) {
        // console.log(data.id);
        this.recargarOfertas();
      }

    });


  }
  recargarOfertas() {
    this.loading = true;
    this.api.getListaOfertas().subscribe(listaOfertas => {
      this.ofertas = listaOfertas;
      this.loading = false;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  ngOnInit() {
  }
  eliminarOferta(id) {
    console.log(id);
    this.loading = true;
    this.api.deleteOferta(id).subscribe(res => {
      this.loading = false;
      if (res !== 'success') {
        console.log(res);
      } else {
        this.recargarOfertas();
      }
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }
  getImageUrl(oferta) {
    return environment.baseUrl + '/img/postulaciones/' + oferta.id + '.jpg?rand=' + Math.random();
  }
}
