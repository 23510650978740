import { Component, OnInit } from '@angular/core';
import { ImagenBottom } from 'src/app/models/Imagenbottom';
import { ActivatedRoute, Router } from '@angular/router';
import { ImagenesbottomapiService } from '../../../services/imagenesbottomapi.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-formimagenbottom',
  templateUrl: './formimagenbottom.component.html',
  styleUrls: ['./formimagenbottom.component.scss']
})
export class FormimagenbottomComponent implements OnInit {

  imagenbottom: ImagenBottom = { nombre: '', orden: 0, descripcion: '', id: 0 };
  loading: boolean;
  error: boolean;
  filedata: any;
  myFile: any;
  constructor(private actRoute: ActivatedRoute, private api: ImagenesbottomapiService, private router: Router) {
    actRoute.params.subscribe(data => {
      if (data.id) {
        // console.log(data.id);
        this.getImagenBottom(data.id);
      }

    });
  }
  fileEvent(e) {
    this.filedata = e.target.files[0];
  }
  enviarImagen() {
    if (this.filedata) {
      const myFormData = new FormData();
      myFormData.append('image', this.filedata);
      console.log(this.filedata);
      this.api.enviarFoto(myFormData, this.imagenbottom.id).subscribe(data => {
        if (data) {
          console.log('foto enviada correctamente');
        }
        this.loading = false;
        this.redirectToList();
      }, (error) => {
        console.log(error);
        this.loading = false;
      });
    } else {
      this.loading = false;
      this.redirectToList();
    }
  }
  redirectToList() {
    this.router.navigateByUrl('/imagenesbottom');
  }
  getImagenBottom(id: string) {
    this.api.getImagenBottom(id).subscribe(data => {
      if (data) {
        this.imagenbottom = data;
        this.error = false;
      } else {
        this.error = true;
      }
      // console.log(data);

      this.loading = false;
    });
  }

  ngOnInit() {
  }
  guardarDatos() {
    console.log(this.imagenbottom);
    if (this.imagenbottom.id === 0) {
      this.api.createImagenBottom(this.imagenbottom).subscribe(imagenbottom => {
        this.loading = false;
        console.log(imagenbottom)
        this.imagenbottom = imagenbottom;
        this.enviarImagen();
      }, (error) => {
        console.log(error);
        this.loading = false;
      });
    } else {
      this.api.updateImagenBottom(this.imagenbottom).subscribe(imagenbottom => {
        this.loading = false;
        this.imagenbottom = this.imagenbottom;
        this.enviarImagen();
      }, (error) => {
        console.log(error);
        this.loading = false;
      });
    }
  }
  getImageUrl(imagenBottom){
    return environment.baseUrl+'/img/imagenesbottom/'+imagenBottom.id+'.jpg';
  }
}
