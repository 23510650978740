import { Component, OnInit } from '@angular/core';
import { Empresa } from 'src/app/models/Empresa';
import { EmpresaapiService } from 'src/app/services/empresaapi.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-listaempresa',
  templateUrl: './listaempresa.component.html',
  styleUrls: ['./listaempresa.component.scss']
})
export class ListaempresaComponent implements OnInit {

  empresas: Empresa[] = [];
  loading: boolean;
  constructor(private api: EmpresaapiService, private actRoute: ActivatedRoute) {
    // constructor(){
    actRoute.params.subscribe(data => {
      if (data) {
        // console.log(data.id);
        this.recargarEmpresas();
      }

    });


  }
  recargarEmpresas() {
    this.loading = true;
    this.api.getListaEmpresas().subscribe(listaEmpresas => {
      this.empresas = listaEmpresas;
      this.loading = false;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  ngOnInit() {
  }
  eliminarEmpresa(id) {
    console.log(id);
    this.loading = true;
    this.api.deleteEmpresa(id).subscribe(res => {
      this.loading = false;
      if (res !== 'success') {
        console.log(res);
      } else {
        this.recargarEmpresas();
      }
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }


}
