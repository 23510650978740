import { Injectable } from '@angular/core';
import { BaseService } from './baseapi.service';
import { map } from 'rxjs/operators';
import { ImagenTopSingleResponse, ImagenTop, ImagenTopResponse } from '../models/Imagentop';

@Injectable({
  providedIn: 'root'
})
export class ImagenestopapiService extends BaseService {

  createImagenTop(imagentop: ImagenTop) {
    return this.postBaseRequest<ImagenTopSingleResponse>('api/imagenestop', imagentop)
      .pipe(map(data => data.data));
  }
  updateImagenTop(imagentop: ImagenTop) {
    return this.putBaseRequest<ImagenTopSingleResponse>('api/imagenestop/' + imagentop.id, imagentop)
      .pipe(map(data => data.data));
  }
  deleteImagenTop(id) {
    return this.deleteBaseRequest<ImagenTopSingleResponse>('api/imagenestop/' + id)
      .pipe(map(data => data.res));
  }
  getListaImagenTops() {
    return this.getBaseRequest<ImagenTopResponse>('api/imagenestop')
      .pipe(map(data => data.data));
  }
  getImagenTop(id: string) {
    return this.getBaseRequest<ImagenTopSingleResponse>('api/imagenestop/' + id)
      .pipe(map(data => data.data));
  }
  enviarFoto(datos: any, id) {
    return this.postFileRequest<ImagenTopSingleResponse>('api/imagenestop/' + id + '/foto', datos)
      .pipe(map(data => data.data));

  }
}
