import { Injectable } from '@angular/core';
import { Oferta, OfertaSingleResponse, OfertaResponse } from 'src/app/models/Oferta';
import { map } from 'rxjs/operators';
import { BaseService } from './baseapi.service';

@Injectable({
  providedIn: 'root'
})
export class OfertasapiService extends BaseService {



  createOferta(oferta: Oferta) {
    return this.postBaseRequest<OfertaSingleResponse>('api/ofertas', oferta)
      .pipe(map(data => data.data));
  }
  updateOferta(oferta: Oferta) {
    return this.putBaseRequest<OfertaSingleResponse>('api/ofertas/' + oferta.id, oferta)
      .pipe(map(data => data.data));
  }
  deleteOferta(id) {
    return this.deleteBaseRequest<OfertaSingleResponse>('api/ofertas/' + id)
      .pipe(map(data => data.res));
  }

  getListaOfertas() {
    return this.getBaseRequest<OfertaResponse>('api/ofertas')
      .pipe(map(data => data.data));
  }
  getOferta(id: string) {
    return this.getBaseRequest<OfertaSingleResponse>('api/ofertas/' + id)
      .pipe(map(data => data.data));
  }
  enviarFoto(datos: any, id) {
    return this.postFileRequest<OfertaSingleResponse>('api/ofertas/' + id + '/foto', datos)
      .pipe(map(data => data.data));

  }
}
