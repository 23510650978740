import { Injectable } from '@angular/core';
import { BaseService } from './baseapi.service';
import { map } from 'rxjs/operators';
import { Administrador, AdministradorSingleResponse, AdministradorResponse } from '../models/Administrador';

@Injectable({
  providedIn: 'root'
})
export class AdministradorapiService extends BaseService {

  createAdministrador(administrador: Administrador) {
    return this.postBaseRequest<AdministradorSingleResponse>('api/administradores', administrador)
      .pipe(map(data => data.data));
  }
  updateAdministrador(administrador: Administrador) {
    return this.putBaseRequest<AdministradorSingleResponse>('api/administradores/' + administrador.id, administrador)
      .pipe(map(data => data.data));
  }
  deleteAdministrador(id) {
    return this.deleteBaseRequest<AdministradorSingleResponse>('api/administradores/' + id)
      .pipe(map(data => data.res));
  }


  getListaadministradores() {
    return this.getBaseRequest<AdministradorResponse>('api/administradores')
      .pipe(map(data => data.data));
  }
  getAdministrador(id: string) {
    return this.getBaseRequest<AdministradorSingleResponse>('api/administradores/' + id)
      .pipe(map(data => data.data));
  }

}
