import { Component, OnInit } from '@angular/core';
import { ImagenTop } from 'src/app/models/Imagentop';
import { ImagenestopapiService } from 'src/app/services/imagenestopapi.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-listaimagentop',
  templateUrl: './listaimagentop.component.html',
  styleUrls: ['./listaimagentop.component.scss']
})
export class ListaimagentopComponent implements OnInit {


  imagenestop: ImagenTop[] = [];
  loading: boolean;
  constructor(private api: ImagenestopapiService, private actRoute: ActivatedRoute) {
    // constructor(){
    actRoute.params.subscribe(data => {
      if (data) {
        // console.log(data.id);
        this.recargarImagenesTops();
      }

    });
  }
  recargarImagenesTops() {
    this.loading = true;
    this.api.getListaImagenTops().subscribe(listaImagenesTops => {
      this.imagenestop = listaImagenesTops;
      this.loading = false;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  ngOnInit() {
  }
  eliminarImagenesTop(id) {
    console.log(id);
    this.loading = true;
    this.api.deleteImagenTop(id).subscribe(res => {
      this.loading = false;
      if (res !== 'success') {
        console.log(res);
      } else {
        this.recargarImagenesTops();
      }
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }
  getImageUrl(imagenTop){
    return environment.baseUrl+'/img/imagenestop/'+imagenTop.id+'.jpg';
  }
}
