import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PostulacionesapiService } from '../../../services/postulacionesapi.service';
import { Curriculum, CurriculumCompleto, DatosAcademico, Trabajo } from '../../../models/DatosHojaDeVida';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-unahoja',
  templateUrl: './unahoja.component.html',
  styleUrls: ['./unahoja.component.scss']
})
export class UnahojaComponent implements OnInit {

  curriculum: CurriculumCompleto;
  loading: boolean;
  error: boolean;
  constructor(private actRoute: ActivatedRoute, private api: PostulacionesapiService, private router: Router) {
    actRoute.params.subscribe(data => {
      if (data.id) {
        // console.log(data.id);
        this.getCurriculum(data.id);
      }
    });
  }

  getCurriculum(id: string) {
    this.api.getUnCurriculum(id).subscribe(data => {
      if (data) {
        this.curriculum = data;
        this.error = false;
      } else {
        this.error = true;
      }
      // console.log(data);

      this.loading = false;
    });
  }
  getFechaNacimiento(objCurriculum: CurriculumCompleto) {
    return objCurriculum.datosPersonales.diaNacimiento + '/' +
      objCurriculum.datosPersonales.mesNacimiento + '/' + objCurriculum.datosPersonales.anionacimiento;
  }
  getNivelAcademico(objDato: DatosAcademico) {
    switch (objDato.nivelAcademico) {
      case '0':
        return 'Bachiller';
      case '1':
        return 'Técnico';
      case '2':
        return 'Licenciatura';
      case '3':
        return 'Diplomado';
      case '4':
        return 'Maestría';
      case '5':
        return 'Doctorado';
    }
  }
  getExperiencia(experiencia: string) {
    switch (experiencia) {
      case '0':
        return '< 1 año';
      case '1':
        return '1 año';
      case '2':
        return '2 años';
      case '3':
        return '5 años';
      case '4':
        return '> 5 años';
    }
  }
  getDisponibilidad(disponibilidad: string) {
    switch (disponibilidad) {
      case '0':
        return 'No está disponible';
      case '1':
        return 'Ahora mismo';
      case '2':
        return '2 semanas';
      case '3':
        return '1 mes';
      case '4':
        return '2 meses';
    }
  }
  getCurriculumImage() {
    return `${environment.baseUrl}/img/curriculums/${this.curriculum.id}.jpeg`;
}
  ngOnInit() {
  }
}
