import { Component, OnInit } from '@angular/core';
import { CategoriaEmpleo } from '../../../models/CategoriaEmpleo';
import { CategoriasempleoapiService } from '../../../services/categoriasempleoapi.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-lista-categoriasempleo',
  templateUrl: './listacategoriasempleo.component.html',
  styleUrls: ['./listacategoriasempleo.component.scss']
})
export class ListaCategoriasEmpleoComponent implements OnInit {

  categorias: CategoriaEmpleo[] = [];
  loading: boolean;
  constructor(private api: CategoriasempleoapiService, private actRoute: ActivatedRoute) {
    // constructor(){
    actRoute.params.subscribe(data => {
      if (data) {
        // console.log(data.id);
        this.recargarCategorias();
      }

    });


  }
  recargarCategorias() {
    this.loading = true;
    this.api.getListaCategoriasEmpleo().subscribe(listaCategorias => {
      this.categorias = listaCategorias;
      this.loading = false;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  ngOnInit() {
  }
  eliminarCategoria(id) {
    console.log(id);
    this.loading = true;
    this.api.deleteCategoria(id).subscribe(res => {
      this.loading = false;
      if (res !== 'success') {
        console.log(res);
      } else {
        this.recargarCategorias();
      }
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

}
