import { Component, OnInit } from '@angular/core';
import { Carrera } from 'src/app/models/Carrera';
import { CarreraapiService } from 'src/app/services/carreraapi.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-listacarreras',
  templateUrl: './listacarreras.component.html',
  styleUrls: ['./listacarreras.component.scss']
})
export class ListacarrerasComponent implements OnInit {

  carreras: Carrera[] = [];
  loading: boolean;
  constructor(private api: CarreraapiService, private actRoute: ActivatedRoute) {
    // constructor(){
    actRoute.params.subscribe(data => {
      if (data) {
        // console.log(data.id);
        this.recargarCarreras();
      }

    });


  }
  recargarCarreras() {
    this.loading = true;
    this.api.getListaCarreras().subscribe(listaCarreras => {
      this.carreras = listaCarreras;
      this.loading = false;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  ngOnInit() {
  }
  eliminarCarrera(id) {
    console.log(id);
    this.loading = true;
    this.api.deleteCarrera(id).subscribe(res => {
      this.loading = false;
      if (res !== 'success') {
        console.log(res);
      } else {
        this.recargarCarreras();
      }
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

}
